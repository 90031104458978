.looper{
    position: relative;
}
.looper .message{
    /* display: none; */
    float: left;
    margin-top: -50px;
    opacity: 0;
    transition: opacity .5s;
}
.looper .message.current{
    display: inline-block;
    margin-top: 0;
    opacity: 1;
    /* transition: opacity .5s; */
}