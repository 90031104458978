body{
  font-family: 'Open Sans', sans-serif;
  background: #FCFCFC;
}

.twitter-tweet-button{
  margin-bottom: -4px;
}

/* Menu */
.navbar{
  transition: all .5s;
}
.navbar .navbar-brand{
  font-family: 'Baloo Bhai', cursive;
}
.light .navbar{
  background: linear-gradient(40deg, rgb(243, 79, 16), rgb(255, 139, 88) 70%);
  /* background: #DDD; */
}
.light .navbar-text{ color: #FFF; }
.light .navbar-text code{ color: rgb(251, 255, 0); }
.dark .navbar{
  background: linear-gradient(40deg, rgb(7, 19, 86), rgb(27, 63, 167) 70%);
  /* background: #26242E; */
}
.dark .navbar-text{ color: #FFF; }

.dragging .editor-wrap textarea{
  position: relative;
  opacity: .5;
  z-index: 1;
}
.drop-message{ display: none; }
.dragging .drop-message{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #FFF;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  background: rgba(255,255,255,.01);
  z-index: 0;
}

/* Tip */
.alert-tip{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.alert-tip .content{
  display: inline-block;
  min-width: 50px;
  padding: 5px 15px;
  color: #FFF;
  background: #007bff;
  border-radius: 25px;
}

/* Modal */
.alert-modal{
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,.3);
  z-index: 100;
}
.alert-modal .content{
  display: inline-block;
  width: auto;
  max-width: 90%;
  padding: 40px;
  margin: auto;
  background: #FFF;
  border-radius: 15px;
  box-shadow: 0px 0px 40px 0px rgba(0,0,0,.3);
}


/* Dragger */
.resizer{
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 100%;
  padding: 15px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  background: #007bff;
  z-index: 1;
  transition: all .15s;
  cursor: pointer;
}
.resizer:hover{
  padding: 20px;
  margin-top: -20px;
  margin-left: -20px;
}
.light .resizer{ background: rgb(255, 139, 88) }
.dark .resizer{ background: rgb(225, 61, 138) }


/* Editor */
.editor-wrap{
  height: 100%;
}
.editor{
  font-family: 'Courier New', Courier, monospace;
  height: 100% !important;
  border: none;
  resize: none;
  color: #EEE !important;
  border-radius: 0;
  background: #222;
  transition: background .5s;
}
.editor:focus{
  outline: none;
  border: none;
  box-shadow: none;
  background: #111;
}


/* Table */
#table-wrap{
  font-family: 'Open Sans', sans-serif;
  transition: background .25s;
}
table.tably{
  width: 100%;
}
table.tably, 
.tably td, 
.tably th {
  padding: 0;
  text-align: left;
  background: transparent;
}
table.tably b{
  font-weight: 600;
}
.tably-input,
.tably-input:focus{
  outline: none;
}
.tably-input{
  display: block;
  width: 100%;
  padding: 10px 12px;
  border: none;
  /* color: #FFF; */
  background: transparent;
}

/* Light */
#table-wrap.light,
.light .tably{
  background: #FFF;
}
.light .tably, 
.light .tably td, 
.light .tably th {
  border: 1px solid rgba(0,0,0,.1);
}
.light .tably .tably-input,
.light .tably input.tably-input {
  color: #555;
}
.light .tably .focused,
.light .tably input.tably-input:focus{
  background: rgba(0,0,0,.05)
}

/* Dark */
.dark .outro{ color: #919191; }
#table-wrap.dark,
.dark .tably{
  background: #333;
}
.dark .tably, 
.dark .tably td, 
.dark .tably th {
  border: 1px solid rgba(255,255,255,.2);
}
.dark .tably .tably-input,
.dark .tably input.tably-input {
  color: #CCC;
}
.dark .tably .focused,
.dark .tably input.tably-input:focus{
  color: #EEE;
  background: rgba(255,255,255,.1)
}


/* Switchy */
.light .switchy{ transition: background .5s; }
.light .switchy .option{ transition: color .5s; }
.light .switchy .option.selected{ transition: color .5s; }

.dark .switchy{ background: #333; }
.dark .switchy .option{ color: #777; }
.dark .switchy .option.selected{ color: #EEE; }
.dark .switchy .slider{ background: #222; }