.switchy{
    position: relative;
    display: inline-block;
    border-radius: 20px;
    background: #EEE;
}

.switchy .option{
    position: relative;
    display: inline-block;
    width: 50%;
    padding: 7px 20px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    color: #777;
    z-index: 2;
}

.switchy .option.selected{
    color: #222;
}

.switchy .slider{
    position: absolute;
    display: inline-block;
    width: 50%;
    height: 100%;
    left: 0%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
    z-index: 0;
    transition: left .25s;
}
.switchy.op1 .slider{
    left: 50%;
}